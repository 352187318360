// const changeThemeBtn = document.querySelector("#changeTheme")
// const logoCopyLight = document.querySelector("#logoCopyLight")
// const logoCopyDark = document.querySelector("#logoCopyDark")

// changeThemeBtn.addEventListener("click", () => {
//   // document.body.classList.toggle("dark")
//   if(document.body.classList.contains("dark")){
//     document.body.classList.add("light")
//     document.body.classList.remove("dark")

//     logoCopyLight.classList.add("footer_copy-logo-light-show")
//     logoCopyLight.classList.remove("footer_copy-logo-light-hide")

//     logoCopyDark.classList.add("footer_copy-logo-dark-hide")
//     logoCopyDark.classList.remove("footer_copy-logo-dark-show")

//   }else{
//     document.body.classList.remove("light")
//     document.body.classList.add("dark")

//     logoCopyLight.classList.add("footer_copy-logo-light-hide")
//     logoCopyLight.classList.remove("footer_copy-logo-light-show")

//     logoCopyDark.classList.add("footer_copy-logo-dark-show")
//     logoCopyDark.classList.remove("footer_copy-logo-dark-hide")
    
//   }
// } )